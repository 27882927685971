import './HeroSection.css';

function HeroSection() {
    return (
        <section className="w-11/12 mx-auto max-w-7xl my-10 select-none">
            <div className="text-center border-gray-800 h-40 md:h-60 relative xyz-appear-to xyz-in-to">
                <div className="h-40 md:h-60">
                    <div className="absolute left-1/2 -translate-x-1/2 transition-all hero-animation bg-[#d1037a] mx-auto w-full max-w-4xl rounded-md overflow-hidden">
                        <img className="object-cover h-full w-full dark:hidden" src="/assets/hero-light.webp" alt=""/>
                        <img className="object-cover h-full w-full light:hidden" src="/assets/hero-dark.webp" alt=""/>
                    </div>
                </div>
                <h1 className="flex flex-col text-6xl font-medium absolute left-1/2 -translate-x-1/2 -bottom-[5.25rem] transition-all duration-700 tracking-wider false">
                    <span className="hero-text-animation">Darren</span>
                    <span className="hero-text-animation">Morrison</span>
                </h1>
            </div>
        </section>
    );
}

export default HeroSection;