// src/components/Header.js
import ThemeToggle from './ThemeToggle';

const Header = () => (
    <header id="header" className="flex justify-between items-center w-11/12 mx-auto py-4 max-w-7xl">
        <div className="inline w-8 h-8 dark:fill-light-500 fill-dark-500 dark:hover:fill-light-300 hover:fill-dark-900 transition-colors duration-500">Logo</div>
        <nav className="flex gap-4">
            <button>Resume</button>
            <button>Get in touch</button>
            <ThemeToggle />
        </nav>
    </header>
);

export default Header;