// src/components/ThemeToggle.js
import React, { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';
import {IoMoon, IoSunny} from "react-icons/io5";

const ThemeToggle = () => {
    const { theme, toggleTheme } = useContext(ThemeContext);

    return (
        <button
            onClick={toggleTheme}
            className="p-2 rounded-full">
            {theme === 'light' ? <IoSunny /> : <IoMoon />}
        </button>
    );
};

export default ThemeToggle;
