import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Skills from './components/Skills';
import './App.css';

const App = () => (
    <div className="min-h-screen flex flex-col bg-white dark:bg-black dark:text-white text-dark-600 overflow-hidden pb-10">
        <Header />
        <HeroSection />
        <Skills />
    </div>
);

export default App;