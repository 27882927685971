import SkillModel from './SkillModel.js';
import './Skills.css';

const Skills = () => (
    <section className="grid lg:grid-cols-[auto_325px] gap-10 xl:gap-20 w-11/12 max-w-7xl mt-36 mx-auto xyz-appear-to xyz-in-to">
        <div className="text-center lg:text-left">
            <p>Passionate and driven Computer Engineering graduate, currently employed as a Software Engineer with a focus on backend engineering and embedded firmware development. Adept at designing and implementing efficient, scalable solutions that streamline processes and drive business growth.</p><br></br>
            <p>My strong business-oriented mindset complements my technical expertise, allowing me to effectively collaborate with cross-functional teams and deliver exceptional results. As a lifelong learner, I am committed to continuously expanding my skillset and staying up-to-date with the latest industry trends. Excited to connect with like-minded professionals and explore new opportunities for growth.</p>
        </div>
        <div className="lg:col-start-1 grid gap-5 grid-cols-2 lg:grid-cols-3">
            <SkillModel skill_name="Embedded C"
                        text="something interesting"
                        img_url="../assets/c-.png"
                        color2="#6EA0D4"
            />
            <SkillModel skill_name="PHP"
                        text="something more interesting"
                        img_url="../assets/php.png"
                        color2="#777BB3"
            />
            <SkillModel skill_name="Python"
                        text="something more interesting"
                        img_url="../assets/python.png"
                        color2="#FFDE59"
            />

            <SkillModel skill_name="Blah"
                        text="something"
                        img_url="/assets/code.png"
                        color2="orange"
            />
            <SkillModel skill_name="Blah"
                        text="something"
                        img_url="/assets/code.png"
                        color2="purple"
            />
            <SkillModel skill_name="Blah"
                        text="something"
                        img_url="/assets/code.png"
                        color2="green"
            />

            <SkillModel skill_name="Blah"
                        text="something"
                        img_url="/assets/code.png"
                        color2="red"
            />
            <SkillModel skill_name="Blah"
                        text="something"
                        img_url="/assets/code.png"
                        color2="gray"
                        col_count="col-span-2"
            />
        </div>
        <div className="flex flex-col gap-3 lg:col-start-2 lg:row-span-2 h-max lg:row-start-1">
            <div className="flex flex-col gap-5 dark:bg-dark-600 bg-light-300 p-4 rounded shadow-md">
                <div className="flex flex-col gap-0.5">
                    <span className="text-sm font-medium">Skills</span>
                    <span className="dark:text-light-500 text-gray-600 text-xs">
                        <div className="flex gap-2 flex-wrap">
                            <strong className="px-2 py-0.5 border-[1.5px] dark:border-light-500 border-gray-400 dark:text-light-500 text-gray-600 rounded-full font-normal">
                            Embedded Firmware Development
                            </strong>
                            <strong className="px-2 py-0.5 border-[1.5px] dark:border-light-500 border-gray-400 dark:text-light-500 text-gray-600 rounded-full font-normal">
                            Backend Development
                            </strong>
                            <strong className="px-2 py-0.5 border-[1.5px] dark:border-light-500 border-gray-400 dark:text-light-500 text-gray-600 rounded-full font-normal">
                            Cloud Architecture
                            </strong>
                        </div>
                    </span>
                </div>
                <div className="flex flex-col gap-0.5">
                    <span className="text-sm font-medium">Location</span>
                    <span className="dark:text-light-500 text-gray-600 text-xs">
                        <div className="not-italic ">Waterloo, Ontario</div>
                    </span>
                </div>
                <div className="flex flex-col gap-0.5">
                    <span className="text-sm font-medium">Email</span>
                    <span className="dark:text-light-500 text-gray-600 text-xs">
                        <div>
                            <a href="mailto:darren09@live.ca">darren09@live.ca</a>
                        </div>
                    </span>
                </div>
                <div className="flex flex-col gap-0.5">

                    <span className="text-sm font-medium">Education</span>
                    <span className="dark:text-light-500 text-gray-600 text-xs">
                        <div className="not-italic ">Bachelor of Software Engineering</div>
                        <div className="not-italic ">Computer Engineering Advance Diploma</div>
                        <div className="not-italic ">Certificate in Business Administration</div>
                    </span>
                </div>
                <div className="flex gap-4 mt-4">
                    <a href="https://github.com/DarmorGamz" target="_blank" rel="noreferrer">
                        <button className="w-8 h-8 dark:bg-dark-500 p-1.5 rounded dark:fill-light-300">
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>GitHub</title>
                                <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"></path>
                            </svg>
                        </button>
                    </a>
                </div>
            </div>
            <a href="mailto:darren09@live.ca" className="glow-on-hover rounded flex justify-center items-center text-light-500 transition-colors hover:text-light-300 text-sm dark:bg-dark-600 bg-light-300 shadow-md xyz-appear-to xyz-in-to">
                Contact Me
            </a>
        </div>
    </section>
);

export default Skills;