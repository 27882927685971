const SkillModal = ({skill_name, text, img_url, color2, col_count}) => (
    <div className={col_count}>
        <div className="shadow-md h-full dark:bg-dark-600 p-4 rounded relative group bg-opacity-100">
            <div className="absolute z-10 -right-2 -top-2 w-8 h-8 rounded overflow-hidden shadow">
                <img className="w-full h-full object-cover" alt="" src={img_url}></img>
            </div>
            <div className="overflow-hidden absolute inset-0">
                <div className="absolute -right-8 -top-12 w-20 h-20 blur-[50px] transition-all duration-700 group-hover:w-40 group-hover:h-40 group-hover:blur-[100px]" style={{background: color2}}>
                </div>
            </div>
            <div className="flex flex-col gap-1">
                <span className="font-medium">{skill_name}</span>
                <p className="text-xs font-light dark:text-light-500 leading-[1.1rem]">
                    {text}
                </p>
            </div>
        </div>
    </div>
);

export default SkillModal;